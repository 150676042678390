import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import Header from "../header";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import methodModel from "../../../methods/methods";

const Layout = ({ children }) => {
  let user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const [sidebar, setsidebar] = useState(false);
  const history = useNavigate();
  const URLPath = window.location.pathname;
  const ActivePlan = useSelector((state) => state.activePlan);
  // useEffect(() => {
  //   if (user && !user.loggedIn) {
  //     history('/login');
  //   }
  // }, [searchState])

  const router = () => {
    let route = localStorage.getItem("route");
    history(route);
  };

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div component="layout">
        <div onClick={(e) => router()} id="routerDiv"></div>
        <Header />
        <div
          className={`main-wrapper d-flex ${
            user?.sidebar ? "active-sidebar" : ""
          } ${sidebar ? "sidebar_active" : ""}`}
        >
          <div className="sidebar_height">
            <div className="main-sidebar d-md-block">
              <div
                className="sidebar-brand p-3 pt-4  text-left pl-5"
                style={{
                  marginTop: "5px",
                  height: "50px",
                  marginBottom: "2px",
                }}
              >
                <label className="editLogo">
                  <img
                    src="/assets/img/favicon_2.png"
                    width="150px"
                    onClick={(e) => {
                      history(`/app/qrcodes`);
                    }}
                    className="img_sidebar"
                  />
                  <img
                    src="/assets/img/favicon.png"
                    className="img_sidebar2"
                  ></img>
                </label>
              </div>
              {user?.logo ? (
                <div
                  className="d-flex justify-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={methodModel.userImg(user?.logo || "")}
                    style={{
                      width: "40px",
                      marginBottom: "2px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : null}
              <div className="sidebar-btns">
                <Link to={`/app/qrcodes`} className="light-btn name_px mb-2">
                  <span
                    className=""
                    onClick={() => {
                      localStorage.setItem("qr", true);
                    }}
                  >
                    New QR Code
                  </span>
                  <img src="/assets/img/qr-images/add_circle.svg" />
                </Link>
                {ActivePlan?.name == "starter" || ActivePlan?.name == "free" ? (
                  <p
                    onClick={() => {
                      toast.error("Bulk Qr codes not include in your plan");
                    }}
                    className="dark-btn name_px mt-2 pointer"
                  >
                    <span className="">Bulk QR Code</span>
                    <img src="/assets/img/qr-images/plus.svg" />
                  </p>
                ) : (
                  <Link to={`/app/bulkqr`} className="dark-btn name_px mt-2">
                    <span className="">Bulk QR Code</span>
                    <img src="/assets/img/qr-images/plus.svg" />
                  </Link>
                )}
              </div>
              <Sidebar />
            </div>
          </div>
          {sidebar ? (
            <span
              class="material-icons right"
              onClick={(e) => setsidebar(false)}
            >
              keyboard_arrow_right
            </span>
          ) : (
            <span class="material-icons left" onClick={(e) => setsidebar(true)}>
              keyboard_arrow_left
            </span>
          )}
          <main className="main pt-3">
            <div className="mainarea">{children}</div>
          </main>
        </div>
      </div>
      {/* <ToastContainer position="top-right" /> */}
    </>
  );
};
export default Layout;
